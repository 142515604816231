import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useFarmStore = defineStore('farm', () => {
  const farmKey = ref(Math.random())
  const parkKey = ref(Math.random())
  const changeKey = (key: string) => {
    if (key === 'farm') {
      farmKey.value = Math.random()
    } else if (key === 'park') {
      parkKey.value = Math.random()
    }
  }

  return { farmKey, parkKey, changeKey }
})
