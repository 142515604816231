export default {
  title: '设备消息推送',
  tip: '设备消息推送URI也称为在线推送标识。它是用于在线推送设备消息的一个特定的URI，里面包含设备的ID、推送协议和一些其他参数，可以让系统或者应用程序很方便地向设备发送推送消息，该URL需支持POST请求。',
  btnTest: '测试',
  btnSave: '保存',
  btnDel: '删除',
  delTip: '删除后将无法接收到消息推送，确定删除么？',
  delTitle: '删除提示',
  saveSuccess: '保存推送URL成功',
  saveError: '保存推送URL失败',
  testSuccess: '测试通过',
  testError: '测试失败',
  delSuccess: '删除成功',
  delError: '删除失败',
  btnCancel: '取消',
  notifyUrlPla: '请输入HTTP/HTTPS URI',
  enter: '请输入',
}
