export default {
  labelName: '告警推送名称',
  labelStrategy: '告警策略',
  btnSearch: '查询',
  btnReset: '重置',
  btnNew: '添加告警推送',
  colName: '告警推送名称',
  colTriggerName: '告警策略',
  colPushType: '推送机制',
  colContact: '告警联系人',
  colOperation: '操作',
  delTip: '你确定要删除这条告警推送吗？',
  btnEdit: '编辑',
  btnDel: '删除',
  btnView: '查看',
  actionTypeOnce: '仅第一次',
  actionTypeRepeat: '重复',
  actionTypeStep: '间隔',
  delSuccess: '删除成功',
  delError: '删除失败',
  labelPushName: '推送名称',
  labelTriggerId: '告警策略',
  labelActionType: '推送机制',
  labelActionInternal: '间隔时间',
  labelReachUserIds: '告警联系人',
  btnSave: '保存',
  btnCancel: '取消',
  time5Min: '5分钟',
  time10Min: '10分钟',
  time30Min: '30分钟',
  time1H: '1小时',
  time24H: '24小时',
  titleView: '查看告警推送',
  titleEdit: '编辑告警推送',
  titleNew: '新建告警推送',
  opSuccess: '操作成功',
  delTitle: '删除提示',
  btnOk: '确认',
  enterMsg: '请输入',
  selectMsg: '请选择',
  enter: '请输入',
  pushLabel: '推送时间',
  pushOps1: '一直',
  pushOps2: '间隔1小时',
  pushOps3: '间隔1天',
}
