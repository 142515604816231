export default {
  platformName: 'TianShu AIoT Platform',
  serviceError: 'Service exception',
  loginOuttime: 'Login has expired',
  satelliteMap: 'Satellite',
  streetMap: 'Street',
  terrainMap: 'Terrain',
  btnCancel: 'Cancel',
  btnYes: 'Confirm',
  timeSelect1: 'Today',
  timeSelect2: 'The last month',
  timeSelect3: 'The last three months',
  timeSelect4: 'The last half a year',
  timeSelect5: 'The last year',
  chinese: 'Chinese',
  english: 'English',
  btnSearch: 'Query',
  btnReset: 'Reset',
  delTitle: 'Delete confirmation',
  changeImg: 'Replace photo',
  previewImg: 'Preview image',
  uploadImgErr: 'Upload failed, please upload again',
}
