// 系统管理
import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 首页-设备告警汇总统计
export const summarizeDeviceAlarmInfo = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/summarize/device/info',
    params,
  })
}

// 首页-设备告警汇总统计2
export const summarizeDeviceLogInfo = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/summarize/devicelog/info',
    params,
  })
}

// 首页-设备告警汇总图表
export const summarizeDeviceAlarmChart = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/summarize/device/chart',
    params,
  })
}

// 首页-设备告警汇总图表
export const summarizeDeviceMsgChart = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/summarize/device/msg/chart',
    params,
  })
}

//  首页-公告
export const announceQuery = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/announce/query',
    params,
  })
}
