export default {
  apiCreate: 'API授权密钥创建',
  createTip:
    '云开发API为物联网平台提供用于开发应用程序的接口。通过云开发提供的云服务和API，物联网开发者可以便捷地实现设备接入、数据存储、实时数据监控、消息推送等功能，快速搭建完整的物联网应用场景。',
  btnCreate: '创建密钥',
  apiTitle: '密钥管理',
  updateTime: '上次更新时间',
  btnReset: '重置密钥',
  btnDel: '删除密钥',
  delTitle: '删除提示',
  delTip: '删除秘钥，会导致使用此秘钥的服务都将无法被调用，确定删除么？',
  btnCancel: '取消',
  btnDelete: '删除',
  resetTitle: '重置密钥',
  rersetTip:
    '重置秘钥，会导致使用此秘钥的服务都将无法被调用，需更换新秘钥调用服务。确定重置么？',
  btnYes: '确定',
  delSuccess: '删除密钥成功',
  delError: '删除密钥失败',
  createSuccess: '创建密钥成功',
  createError: '创建密钥失败',
  resetSuccess: '重置密钥成功',
  resetError: '重置密钥失败',
  formatterTime: 'YYYY年MM月DD日 HH时mm分',
}
