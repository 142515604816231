export default {
  usernamePla: 'Please enter account',
  passwordPla: 'Please enter password',
  forgetPwd: 'Forget password',
  btnLogin: 'Login',
  loginMsg: 'Account or password error',
  loginSuccess: 'Login successful',
  back: 'Back to login',
  emailPla: 'Please enter your email',
  codePla: 'Enter code',
  getCode: 'Get verification code',
  resetCode: 'Reacquire {number}s', // For example, reacquire 10s
  newPwdPla: 'Please enter a new password',
  btnReset: 'Reset Password',
  newPasswordPla: 'Please enter a new password',
  pwdMsg: 'combination of 8-16 letters, numbers, and special symbols',
  codeMsg: 'Please enter the verification code',
  codeError: 'Verification code error',
  emailFirst: 'Please enter your email first',
  emailMsg: 'Please enter the correct email',
  updatePwdTitle: 'Change password',
  oldPwd: 'Old password',
  oldPwdPla: 'Please enter the old password',
  newPwd: 'New Password',
  confirmPwd: 'Confirm new password',
  confirmPwdPla: 'Please enter the new password again',
  btnCancel: 'Cancel',
  btnYes: 'Confirm',
  pwdMsgMatch: 'The passwords entered twice do not match',
  oldMsg: 'Please enter the original password',
  pwdAgain: 'Please enter the new password again',
  updatePwd: 'Change password',
  signOut: 'Exit',
  updateSuccess: 'Password changed successfully',
  oldPwdError: 'The original password is wrong',
}
