export default {
  title: 'API列表',
  tip: '物联网平台API是指在物联网平台中，开发者或设备管理者可以通过API接口轻松地调用平台的各种功能和服务，包括设备管理、实时数据监控、消息推送等，从而实现更丰富的应用场景和服务。对接详细流程请',
  seeDoc: '查看详细API文档',
  colPage: '查询设备列表',
  colDetail: '查询设备详情',
  colLog: '查询设备日志',
  colLocation: '查询设备定位',
  colHisLocation: '查询设备历史轨迹',
  index: '序号',
  apiName: 'API名称',
  url: 'URL',
  desc: '说明',
  noData: '暂无数据',
}
