export default {
  btnAdd: 'New Organization',
  btnEdit: 'Edit',
  btnDelete: 'Delete',
  delTip: 'Please confirm whether to delete the organization?',
  name: 'Name',
  topOrg: 'Superior Organization',
  desc: 'Description',
  sort: 'Order',
  btnCancel: 'Cancel',
  btnYes: 'Confirm',
  newTitle: 'Create New Organization',
  editTitle: 'Modify Organization',
  enter: 'Please enter',
  enterMsg: 'Please enter a number',
  select: 'Please select',
}
