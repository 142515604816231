import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 逆地理编码
export const queryLocationRgeo = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/location/rgeo',
    params,
  })
}
