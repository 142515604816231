import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 重置密码（忘记密码）
export const passwordReset = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/user/password/reset',
    params,
  })
}
// 修改密码
export const passwordUpdate = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/user/password/update',
    params,
  })
}
// 发送验证码
export const verificationcodeSend = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/user/verificationcode/send',
    params,
    userConfig: {
      headers: {
        emptyAuthorization: true,
      },
    },
  })
}
