export default {
  labelTime: 'Date Time',
  timeStart: 'Start time',
  timeEnd: 'End time',
  labelType: 'Alarm Type',
  autoFetch: 'Auto Refresh',
  autoFetchTip: '5s automatic refresh',
  colTime: 'Time',
  colName: 'Alarm name',
  colMsg: 'Warning message',
  colStatus: 'Notification status',
  colPhone: 'Mobile phone number',
  colEmail: 'Email',
  StatusSuccess: 'Success',
  statusFail: 'Failed',
  btnSearch: 'Query',
  deviceName: 'Device Name',
  triggername: 'Alarm Scenario',
  alarmLevel: 'Alarm Level',
  triggerType: 'Alarm Type',
  timeRange: 'Time Range',
  startTime: 'Start Time',
  endTime: 'End Time',
  triggerCondition: 'Alarm scenario',
  triggerDevice: 'Alarm Device',
  alarmTime: 'Alarm Time',
  pushStatus: 'Push Status',
  noData: 'No data yet',
  msgTitleSensorAlarm: 'Sensor threshold alarm',
  msgTitleAoiAlarm: 'Fence alarm',
  deviceAlarm: 'Device alarm',
  releaseAlarm: 'Release',
  releaseAlarmTip: 'Are you sure you want to release this alarm?',
  eventAlarm: 'Event alarm',
  msgTitleSensorIncreAlarm: 'Sensor incremental alarm',
}
