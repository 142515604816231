export default {
  deviceName: 'Device Name',
  deviceNamePla: 'Please select a device',
  autoFetchText: 'Auto Refresh',
  autoFetchTip: 'Automatically refresh every fifteen seconds',
  productType1: 'Multi-mode  gateway',
  productType2: 'Single-mode gateway',
  msgNoLocation: 'There is no device location information',
  device: 'Device Name',
  address: 'Address',
  lat: 'Latitude',
  lng: 'Longitude',
  time: 'Moment',
}
