export default {
  apiCreate: 'API authorization key creation',
  createTip:
    'The cloud development API provides an interface for developing applications for the IoT platform. Through the cloud services and APIs provided by cloud development, IoT developers can easily implement functions such as device access, data storage, real-time data monitoring, and message push, and quickly build complete IoT application scenarios. ',
  btnCreate: 'Create key',
  apiTitle: 'Key Management',
  updateTime: 'Last update time',
  btnReset: 'Reset key',
  btnDel: 'Delete key',
  delTitle: 'Delete Tip',
  delTip:
    'Deleting the secret key will cause the service using this secret key to be unable to be called. Are you sure to delete it? ',
  btnCancel: 'Cancel',
  btnDelete: 'Delete',
  resetTitle: 'Reset key',
  rersetTip:
    'Resetting the secret key will cause the service using this secret key to be unable to be called, and a new secret key needs to be replaced to call the service. Are you sure to reset? ',
  btnYes: 'OK',
  delSuccess: 'Delete the key successfully',
  delError: 'Failed to delete key',
  createSuccess: 'Create key successfully',
  createError: 'Failed to create key',
  resetSuccess: 'Reset the key successfully',
  resetError: 'Failed to reset key',
  formatterTime: 'YYYY-MM-DD HH:mm',
}
