export default {
  strategyName: '告警名称',
  strategyType: '类别',
  strategyStatus: '状态',
  btnNew: '新建告警策略',
  colName: '告警名称',
  colTypeName: '类别',
  colPushMsgTotal: '告警消息',
  colUpdateTime: '时间',
  colStatus: '状态',
  statusOpen: '已开启',
  statusClose: '已关闭',
  colOperation: '操作',
  btnEdit: '编辑',
  btnDel: '删除',
  delTip: '你确定要删除这个告警策略吗？',
  btnView: '查看',
  closeTip: '你确定要关闭这个告警吗？',
  openTip: '你确定要开启这个告警吗？',
  open: '开启',
  close: '关闭',
  delSuccess: '删除成功',
  labelAoi: '告警围栏',
  labelActionType: '生效时间',
  labelTypeName: '告警类别',
  labelDeviceName: '设备名称',
  labelAttr: '告警指标',
  week1: '周一',
  week2: '周二',
  week3: '周三',
  week4: '周四',
  week5: '周五',
  week6: '周六',
  week7: '周日',
  actionTypeAlways: '一直生效',
  labelNameForm: '告警名称',
  labelNamePla: '请输入告警名称',
  labelAoiForm: '围栏选择',
  labelVarForm: '选择变量',
  devicePla: '请选择设备',
  subDevicePla: '选择子设备',
  varPla: '选择变量',
  labelOperatior: '运算符',
  labelThreshold: '阈值',
  thresholdPla: '阈值',
  labelEffective: '生效时间',
  editTitle: '编辑告警策略',
  newTitle: '新增告警策略',
  effectTimeMsg: '请选择自定义时间的选项',
  editSuccess: '编辑告警成功',
  newSuccess: '添加告警成功',
  operatorError: '操作失败',
  timeAlways: '一直生效',
  timeCustom: '自定义时间',
  timeRange: '时间段',
  timeStart: '开始时间',
  timeEnd: '结束时间',
  delError: '删除失败',
  delTitle: '删除确认',
  btnOk: '确认',
  btnCancel: '取消',
  comfirmTitle: '提示',
  enterMsg: '请输入',
  selectMsg: '请选择',
  alarmLevel: '告警级别',
  operator1: '大于',
  operator2: '小于',
  operator3: '等于',
  operator4: '大于等于',
  operator5: '小于等于',
  operator6: '大于等于且小于等于',
  and: ' 并且 ',
  condition: '条件选择',
  conditionAll: '满足全部',
  conditionOne: '满足其中之一',
  mode: '支持模式',
  select: '请选择',
  range: '设置范围',
  keepTime: '持续时间',
  count: '次数',
  add: '添加条件',
  main: '主要',
  minor: '次要',
  urgency: '紧急',
  warn: '警告',
  clean: '清除',
  simple: '简单',
  duringTime: '持续时长',
  duringNum: '持续次数',
  time1: '秒',
  time2: '分',
  time3: '时',
  time4: '天',
  sensorsMsg: '请添加条件',
  triggerCondition: '触发条件',
  alarmConditions: '告警条件',
  deviceName: '设备名称',
  changeValue: '变化值',
  changeValuePla: '请输入变量',
}
