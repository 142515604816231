export default {
  fileType: '文件类型',
  contentType: '内容类型',
  collectionTime: '采集时间',
  fileText: '文本',
  fileImage: '图片',
  fileVideo: '视频',
  fileAudio: '音频',
  rareAnimals: '珍稀动物',
  fire: '火灾',
  latlng: '经纬度',
  deviceSN: '设备SN',
}
