export default {
  deviceTotal: 'Total number of devices',
  activeDeviceTotal: 'Activated devices',
  onlineDeviceTotal: 'Online devices',
  deviceUpMsgTotal: 'Reported message volumes',
  unit1: 'Pieces',
  unit2: 'Bits',
  nav1: 'Device List',
  nav2: 'Device Distribution',
  nav3: 'Device Track',
  nav4: 'Alarm Message',
  deviceActiveTotal: 'Activated ',
  deviceTotalNumber: 'Total numbers',
  lastMonth: 'End of last month',
  deviceTrends: 'Device Historical Trends',
  msgReportTrends: 'Message Historical Trends',
  nav: 'Quick Navigation',
  notice: 'Notice',
  sOnline: 'Online devices',
  sOffline: 'off-line devices',
  sUnActivated: 'Device to be activated',
  deviceTypeMulti: 'Multi-mode',
  deviceTypeSingle: 'Single-mode',
  t1: 'Equipment quantity statistics',
  t2: 'Equipment status statistics',
}
