export default {
  btnNew: '新建围栏',
  btnClose: '退出',
  btnSave: '保存编辑',
  btnEdit: '编辑',
  btnRelativeDevice: '关联设备',
  btnDelete: '删除',
  msgDeleteTip:
    '删除电子围栏，可能会导致关联该电子围栏的告警不可用，确定删除么？',
  deleteTipTitle: '删除提示',
  btnCancel: '取消',
  drawStartTip: '单击开始绘制',
  drawContTip: '点击继续',
  drawEndTip: '单击第一个点结束绘制',
  delSuccess: '删除成功',
  delError: '删除失败',
  labelAoiName: '围栏名称',
  labelRelativeDevice: '关联设备',
  btnYes: '确定',
  newTitle: '新建电子围栏',
  editTitle: '编辑电子围栏',
  enterPla: '请输入',
  editSuccess: '修改电子围栏成功',
  newSuccess: '新建电子围栏成功',
  polygon: '多边形围栏',
  round: '圆形围栏',
  circleTip1: '单击并拖拽画出一个圆',
  circleTip2: '释放鼠标完成绘制',
  radius: '半径',
  typeIn: '进方向',
  typeOut: '出方向',
  typeInOut: '进出方向',
  alarmTip: '自动添加电子围栏告警',
  geoDiretion: '围栏方向',
}
