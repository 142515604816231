import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 分页查询-告警策略列表
export const alarmStrategyPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/page',
    params,
  })
}
// 添加告警策略
export const alarmStrategyAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/add',
    params,
  })
}
// 修改告警策略
export const alarmStrategyUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/update',
    params,
  })
}
// 批量删除告警策略
export const alarmStrategyDelete = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/delete',
    params,
  })
}
// 启用停用告警策略
export const alarmStrategyStartAndStop = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/startAndStop',
    params,
  })
}
// 查询告警策略详情
export const alarmStrategyDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/detail',
    params,
  })
}
// 查询告警联系人
export const alarmContactPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/contact/page',
    params,
  })
}
// 告警下拉选择
export const alarmStrategyTreelist = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/treelist',
    params,
  })
}
// 告警下拉选择
export const queryAlarmMsgPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/msg/page',
    params,
  })
}
// 下拉查询-告警策略列表-包含配置的传感器信息
export const queryAlarmOtherInfoTreelist = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/otherInfoTreelist',
    params,
  })
}

export const queryWarningContactList = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/contact/page',
    params,
  })
}

export const addWarningContact = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/contact/add',
    params,
  })
}

export const updateWarningContact = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/contact/update',
    params,
  })
}

export const deleteWarningContact = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/contact/delete',
    params,
  })
}

export const getWarningStrategyAll = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/treelist',
    params,
  })
}

export const getWarningStrategyPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/page',
    params,
  })
}

export const getWarningContact = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/contact/page',
    params,
  })
}

export const queryPushActionPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/pushAction/page',
    params,
  })
}

// 新增告警推送
export const alarmPushActionAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/pushAction/add',
    params,
  })
}
// 删除告警推送
export const alarmPushActionDelete = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/pushAction/delete',
    params,
  })
}
// 告警推送详情
export const alarmPushActionDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/pushAction/detail',
    params,
  })
}
// 修改告警推送
export const alarmPushActionUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/pushAction/update',
    params,
  })
}
// 告警推送分页
export const alarmPushActionPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/pushAction/page',
    params,
  })
}

// 解除告警
export const alarmMsgRelieve = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/msg/relieve',
    params,
  })
}
