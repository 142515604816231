export default {
  deviceName: '设备名称',
  deviceNamePla: '请选择设备',
  time: '轨迹时间',
  startTime: '开始时间',
  endTime: '结束时间',
  autoFetchText: '自动刷新',
  autoFetchTip: '每十五秒自动点击查询按钮一次',
  btnReset: '重置',
  btnSearch: '查询',
  labelPlayback: '轨迹回放',
  btnAddPoint: '添加轨迹点',
  btnPlaybackDetail: '轨迹详情',
  timeSelect1: '今天',
  timeSelect2: '最近一个月',
  timeSelect3: '最近三个月',
  timeSelect4: '最近半年',
  msgRange: '请选择时间范围',
  msgRangeTime: '时间跨度要小于一年',
  msgSelectDevice: '请选择设备',
  msgNoLocation: '当前时间段内暂无设备定位信息',
  popName: '设备名称',
  popAddress: '设备地址',
  popLat: '纬度',
  popLng: '经度',
  addPoinetTitle: '添加轨迹点',
  address: '详细地址',
  preview: '预览',
  trailTime: '轨迹时间点',
  trailTimePla: '请选择轨迹时间点',
  msgAddress: '请输入详细地址',
  msgLng: '请输入正确的经度',
  msgLat: '请输入正确的纬度',
  msgTime: '请选择轨迹时间点',
  msgSuccess: '添加轨迹点成功',
  trailTableTitle: '轨迹详情',
  btnExport: '导出excel',
  btnYes: '确定',
  colName: '设备名称',
  colAddress: '设备地址',
  latLng: '经度/纬度',
  playBackSendTime: '采集时间',
  msgDownloding: '正在下载',
  collapse: '收起',
  expand: '展开',
  playTrail: '播放轨迹',
  exit: '退出',
  speed: '速度( Km/小时 )',
  index: '序号',
}
