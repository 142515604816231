export default {
  deviceName: 'Device Name',
  deviceNamePla: 'Please select a device',
  time: 'Track Time',
  startTime: 'Start Time',
  endTime: 'End Time',
  autoFetchText: 'Auto Refresh',
  autoFetchTip:
    'Automatically click the query button once every fifteen seconds',
  btnReset: 'Reset',
  btnSearch: 'Query',
  labelPlayback: 'Track playback',
  btnAddPoint: 'Add track point',
  btnPlaybackDetail: 'Track Details',
  timeSelect1: 'Today',
  timeSelect2: 'The last month',
  timeSelect3: 'The last three months',
  timeSelect4: 'The last half a year',
  msgRange: 'Please select a time range',
  msgRangeTime: 'The time span must be less than one year',
  msgSelectDevice: 'Please select a device',
  msgNoLocation:
    'There is no device location information in the current time period',
  popName: 'Device Name',
  popAddress: 'Device Address',
  popLat: 'Lat',
  popLng: 'Lng',
  addPoinetTitle: 'Add track point',
  address: 'Address',
  preview: 'View',
  trailTime: 'Moment',
  trailTimePla: 'Please select the track time point',
  msgAddress: 'Please enter a detailed address',
  msgLng: 'Please enter the correct longitude',
  msgLat: 'Please enter the correct latitude',
  msgTime: 'Please select a track time point',
  msgSuccess: 'Add track point successfully',
  trailTableTitle: 'Track Details',
  btnExport: 'Export to Excel',
  btnYes: 'OK',
  colName: 'Equipment Name',
  colAddress: 'deviceAddress',
  latLng: 'Longitude/Latitude',
  playBackSendTime: 'Collection time',
  msgDownloding: 'Downloading',
  collapse: 'Collapse',
  expand: 'Expand',
  playTrail: 'Play Trajectory',
  exit: 'Exit',
  speed: 'Speed (km/h)',
  index: 'Index',
}
