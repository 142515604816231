import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 获取网关产品信息(除子设备)
export const queryProductGatewayList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/product/gatewayList/query',
    params,
  })
}
// 获取子设备产品信息
export const queryProductSubDeviceList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/product/subDeviceList/query',
    params,
  })
}
// 获取总线类型信息
export const queryProductBusType = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/product/busType/query',
    params,
  })
}
