export default {
  siteName: '站点名称',
  col1: '站点名称',
  col2: '所属组织',
  col3: '设备数量',
  col4: '描述',
  col5: '创建时间',
  btnAdd: '添加站点',
  titleNew: '新建站点',
  titleEdit: '编辑站点',
  uploadPic: '上传图片',
  address: '地址',
  remark: '备注',
  relativeDevice: '关联设备',
  delTip: '你确定要删除这个站点吗？',
  siteNamePla: '请输入站点名称',
  orgPla: '请选择组织',
  addressEdit: '编辑地址',
  remarkPla: '请输入你的备注',
  yes: '确定',
  no: '取消',
  siteInfomation: '站点信息',
  associatedDevices: '关联设备',
  siteMap: '站点地图',
  desc: '描述',
  noAddress: '暂无站点位置信息',
  onlineTip: '在线{text}台',
  noActiveTip: '未激活{text}台',
  offlineTip: '离线{text}台',
}
