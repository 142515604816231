export default {
  btnNew: '创建联系人',
  colName: '名称',
  colPhone: '电话',
  colMail: '邮箱',
  colOp: '操作',
  btnEdit: '编辑',
  btnDel: '删除',
  labelName: '姓名',
  labelPhone: '手机号',
  labelMail: '邮箱',
  delTip: '联系人{name}将无法收到任何告警推送消息，请确认是否删除！',
  phoneMsg: '手机号码校验不通过',
  emailMsg: '邮箱校验不通过',
  titleNew: '创建联系人',
  titleEdit: '编辑联系人',
  createSuccess: '添加成功',
  createError: '添加失败',
  editSuccess: '修改成功',
  editError: '修改失败',
  delSuccess: '删除成功',
  delError: '删除失败',
  btnCancel: '取消',
  btnYes: '确定',
  delTitle: '删除提示',
  nameMsg: '请填写姓名',
  index: '序号',
  enter: '请输入',
  select: '请选择',
}
