export default {
  deviceName: '设备名称',
  deviceNamePla: '请输入设备名称',
  deviceSn: '设备SN',
  productType: '所属产品',
  deviceStatusCode: '状态',
  deviceStatusCodePla: '请选择',
  btnAdd: '添加',
  deviceStatusName: '状态',
  onlineCheck: '在线检测',
  btnEdit: '编辑',
  btnDelete: '删除',
  btnDetail: '详情',
  btnView: '数据查看',
  noData: '暂无数据',
  deviceDeleteTitle: '删除提示',
  deviceDeleteTip:
    '删除设备将删除关联的配置关系，但不影响此设备关联的数据，请确认是否删除？',
  btnCancel: '取消',
  labelNoactive: '未激活',
  labelActive: '已激活',
  labelOffline: '离线',
  labelOnline: '在线',
  colName: '设备名称',
  colSn: '设备SN',
  colOrg: '所属组织',
  colProduct: '所属产品',
  colAddress: '设备地址',
  colCreateTime: '创建时间',
  delSuccessMsg: '删除设备成功',
  delErrorMsg: '删除设备失败',
  commondTip: '采集指令已经下发',
  commondTitle: '提示',
  btnYes: '确定',
  colOperation: '操作',
  accessMethod: '接入方式',
  deviceLabel: '设备标签',
  belogingSite: '所属站点',
  latLng: '经纬度',
  deviceIcon: '设备图标',
}
