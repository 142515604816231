export default {
  add: 'Add',
  edit: 'Edit',
  device: ' Device',
  baseInfo: 'Basic information',
  deviceName: 'Device name',
  deviceNamePla: 'Please enter the device name',
  deviceSn: 'Device SN',
  deviceSnPla: 'Please enter the device SN',
  deviceConfig: 'Device Configuration',
  labelData: 'Data upload',
  varTempalte: 'variable template',
  btnSearch: 'Query',
  dataUnresolve: 'Passthrough',
  deviceLocation: 'Device positioning',
  autoLocation: 'Automatic positioning',
  handLocation: 'Manual positioning',
  deviceMap: 'Device Map',
  map: 'Map',
  btnSave: 'Save',
  btnCancel: 'Cancel',
  msgSelect: 'Please select',
  deviceMsg: 'Please enter the device name',
  deviceSnMsg: 'Please enter the device SN',
  msgSuccess: 'Operation succeeded',
  address: 'Address',
  subDeviceNum: 'Number of sub-device',
  detail: 'Details',
  productType: 'Type',
  ame: 'Name/model',
  producer: 'Brand',
  connectType: 'Connection method',
  varName: 'Variable name',
  unit: 'Unit',
  range: 'Range',
  dataType: 'Data type',
  lng: 'Lng',
  lat: 'Lat',
  preview: 'Preview',
  btnYes: 'OK',
  msgAddress: 'Please enter a detailed address',
  msgLng: 'Please enter the correct longitude',
  msgLat: 'Please enter the correct latitude',
  varCount: 'Variable number',
  operation: 'Operate',
  subDevice: 'Sub-Device',
  relativeSub: 'Associated sub devices',
  relativeSubNum: 'Associated {text} sub devices',
  repeatPort: 'Duplicate port number',
  select: 'Select',
  enter: 'Enter',
  msg1: 'Please enter product name/manufacturer/model',
  msg2: 'Please enter the bus type',
  msg3: 'Please enter the bus ID',
  msg4: 'Please enter the sensor ID',
  btnAdd: 'Add',
  subErrorTip: 'sub device information is incorrect',
  productMsg: 'Please select the product you belong to first',
  deviceSnAliasPla: 'Sensor SN (not mandatory)',
  chooseValue: 'The optional values are :',
  latLngType1: 'DMS',
  latLngType2: 'DD',
  formatterErr: 'Format mismatch',
}
