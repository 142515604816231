import HOME from './home'
import COMMON from './common'
import D_INFO from './device-info'
import D_LOCATION from './device-location'
import D_TRAIL from './device-trail'
import D_LOCUS from './device-locus'
import D_LOG from './device-log'
import D_CREATE from './device-create'
import D_DATA from './device-data'
import STATISTICS from './statistics'
import C_ACCOUNT from './cloud-account'
import C_MSG from './cloud-msg'
import C_API from './cloud-api'
import W_TACTICS from './warning-tactics'
import W_CONTACT from './warning-contact'
import W_PUSH from './warning-push'
import W_MSG from './warning-msg'
import CONFIG from './config'
import MENU from './menu'
import LOGIN from './login'
import ORG from './org'
import SITE from './site'
import FILE from './file'

export default {
  COMMON,
  MENU,
  HOME,
  D_INFO,
  D_LOCATION,
  D_TRAIL,
  D_LOCUS,
  D_LOG,
  D_CREATE,
  STATISTICS,
  C_ACCOUNT,
  C_MSG,
  C_API,
  W_TACTICS,
  W_CONTACT,
  W_PUSH,
  W_MSG,
  CONFIG,
  D_DATA,
  LOGIN,
  SITE,
  ORG,
  FILE,
}
