// 组织管理
import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 查询-组织列表
export const queryOrgTreeList = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/org/manage/treelist',
    params,
  })
}

// 添加组织
export const orgInfoAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/org/manage/add',
    params,
  })
}

// 修改组织
export const orgInfoUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/org/manage/update',
    params,
  })
}

// 修改组织
export const orgInfoDelete = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/org/manage/delete',
    params,
  })
}

// 查询组织详情
export const orgInfoDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/org/manage/detail',
    params,
  })
}
